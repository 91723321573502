section[data-section="red-concesionarios"] {

  .form-control {
    margin: 0 5px 18px 5px;
    border: none;
    border-bottom: 2px solid $celeste;
    border-radius: 0;
    box-shadow: none;
  }

  .title {
    color: $celeste;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 0 10px 5px;
    font-size: 2.8rem;
    letter-spacing: 2px;
  }

  .lista {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item-con {
      flex-basis: 49.3%;
      padding: 20px 30px;
      background: white;
      margin: 0 0 15px 5px;
      border-radius: 3px;
      box-shadow: 0 0 2px 1px rgba(grey, .4);

      @include mobile {
        flex-basis: 100%;
        margin: 10px 0 15px 0;
      }

      h3 {
        color: darken($celeste, 15);
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 8px;
      }

      h4 {
        color: grey;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  }
}