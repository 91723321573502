@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Black.woff2') format('woff2'),
        url('../fonts/FordAntenna-Black.woff') format('woff'),
        url('../fonts/FordAntenna-Black.ttf') format('truetype'),
        url('../fonts/FordAntenna-Black.svg#FordAntenna-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-ExtraLight.woff2') format('woff2'),
        url('../fonts/FordAntenna-ExtraLight.woff') format('woff'),
        url('../fonts/FordAntenna-ExtraLight.ttf') format('truetype'),
        url('../fonts/FordAntenna-ExtraLight.svg#FordAntenna-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Thin.woff2') format('woff2'),
        url('../fonts/FordAntenna-Thin.woff') format('woff'),
        url('../fonts/FordAntenna-Thin.ttf') format('truetype'),
        url('../fonts/FordAntenna-Thin.svg#FordAntenna-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Medium.woff2') format('woff2'),
        url('../fonts/FordAntenna-Medium.woff') format('woff'),
        url('../fonts/FordAntenna-Medium.ttf') format('truetype'),
        url('../fonts/FordAntenna-Medium.svg#FordAntenna-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Regular.woff2') format('woff2'),
        url('../fonts/FordAntenna-Regular.woff') format('woff'),
        url('../fonts/FordAntenna-Regular.ttf') format('truetype'),
        url('../fonts/FordAntenna-Regular.svg#FordAntenna-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Light.woff2') format('woff2'),
        url('../fonts/FordAntenna-Light.woff') format('woff'),
        url('../fonts/FordAntenna-Light.ttf') format('truetype'),
        url('../fonts/FordAntenna-Light.svg#FordAntenna-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Semibold.woff2') format('woff2'),
        url('../fonts/FordAntenna-Semibold.woff') format('woff'),
        url('../fonts/FordAntenna-Semibold.ttf') format('truetype'),
        url('../fonts/FordAntenna-Semibold.svg#FordAntenna-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Thin_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-Thin_1.woff') format('woff'),
        url('../fonts/FordAntenna-Thin_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-Thin_1.svg#FordAntenna-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Black_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-Black_1.woff') format('woff'),
        url('../fonts/FordAntenna-Black_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-Black_1.svg#FordAntenna-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Semibold_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-Semibold_1.woff') format('woff'),
        url('../fonts/FordAntenna-Semibold_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-Semibold_1.svg#FordAntenna-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Regular_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-Regular_1.woff') format('woff'),
        url('../fonts/FordAntenna-Regular_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-Regular_1.svg#FordAntenna-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Medium_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-Medium_1.woff') format('woff'),
        url('../fonts/FordAntenna-Medium_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-Medium_1.svg#FordAntenna-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-Light_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-Light_1.woff') format('woff'),
        url('../fonts/FordAntenna-Light_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-Light_1.svg#FordAntenna-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ford Antenna';
    src: url('../fonts/FordAntenna-ExtraLight_1.woff2') format('woff2'),
        url('../fonts/FordAntenna-ExtraLight_1.woff') format('woff'),
        url('../fonts/FordAntenna-ExtraLight_1.ttf') format('truetype'),
        url('../fonts/FordAntenna-ExtraLight_1.svg#FordAntenna-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

