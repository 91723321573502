footer {
  background: grey;
  height: 100px;
  //margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;


  @include mobile {
    //height: auto;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 5%;
    text-align: center;
  }

  p {
    margin: 0;
    line-height: 0;
    color: white;
    font-size: 1.3rem;
    @include mobile {
      line-height: 1.3;
    }
  }
}