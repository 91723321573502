section[data-section="contacto"]{
  h2{
    margin-bottom: 10px;
  }

  form{
    label{
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
      color:darken($celeste,20);
    }
    .form-control{
      margin-bottom: 5px;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .mapa{
    height: 380px;

    svg{
      height: 100%;
    }
  }
}