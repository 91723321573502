section[data-section="quienes"], section[data-section="comision"] {
  padding: 50px 0;

  .mapa {
    height: 480px;
    //border:1px solid red;

    @include mobile {
      height: 400px;
      text-align: center;
    }

    svg {
      height: 100% !important;
      @include mobile {
        height: auto;
      }
    }
  }

  .mapa2 {
    height: 1200px;
    //border:1px solid red;
    @include mobile {
      height: 400px;
      text-align: center;
      padding: 10px 0;
    }

    svg {
      height: 100% !important;
    }
  }

  .puntos {
    padding-left: 10px;
    li {
      list-style: disc;
      padding-left: 5px;
    }
  }


  h2 {
    background: $celeste;
    padding: 10px;
    color: white;
    width: auto;
    display: inline-block;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }

  h3 {
    font-size: 2.4rem;
    margin-top: 8px;
    text-transform: uppercase;
    //background: $celeste;
    padding: 10px 0;
    color: $celeste;
    width: auto;
    display: inline-block;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }

  p {
    font-size: 1.3rem;
  }

  li {
    color: black;
    font-size: 1.2rem;
    margin-bottom: 10px;

    span {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: grey;
      font-weight: 800;
      display: block;
    }
  }
}