section[data-section="donde-estamos"] {

  .data {
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $celeste;
    color: white;
    flex-direction: column;
    height: 404px;

    @include mobile {
      flex-direction: column;
      height: 200px;
    }
    p{
      line-height: 1.8;
    }
  }

}