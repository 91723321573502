body, html {
  font-size: 10px;

  @include mobile{
    margin-top: 20px;
  }

  p, li, a {
    font-size: 1.5rem;
    font-family: $FordAntenna;
    font-weight: 300;
  }

  ul {
    margin: 0;
    padding: 0;

    //list-style: none;
    //
    //li {
    //  list-style: none;
    //}
  }


  h1, h2, h3, h4 {
    margin: 0;
    font-family: $FordAntenna;
    font-weight: 300;
  }

  .section-page{
    padding: 30px 0;
  }
}