header {
  padding: 11px 5px;
  box-shadow: 0 0 2px grey;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 888;
  background: white;
  display: flex;
  justify-content: space-between;

  @include mobile {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }

  .has-sub {
    position: relative;

    ul.submenu {
      position: absolute;
      left: 0;
      background: white;
      box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.12);
      width: 100%;
      height: auto;
      display: none;

      li {
        //border: 1px solid red;
        display: block;
        width: 100%;

        a {
          display: block;

          &:hover {
            background: rgba(232, 232, 232, 0.49);
            text-decoration: none;
          }
        }
      }
    }


    &:hover {
      .submenu {
        display: block;
      }
    }
  }

  .logo {
    flex-basis: 30%;
    align-items: center;
    align-content: center;
    height: 50px;

    @include mobile {
      flex-basis: 100%;
      height: auto;
      position: relative;
    }

    svg {
      width: 90%;
      @include mobile {
        width: 70%;
      }
    }
  }

  .button-mobile {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: black;
  }

  nav.nav-site {
    flex-basis: 75%;
    height: 50px;
    transition: all 0.3s ease;

    @include mobile {
      position: fixed;
      left: 0;
      top: -100%;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 888;
      flex-direction: column;
      padding: 15%;
    }

    ul {
      display: flex;
      align-content: center;
      align-items: center;
      height: 100%;

      @include mobile {
        padding: 10% 0;
        flex-direction: column;
        height: auto;
      }

      li {

        flex: 1 1;
        display: block;
        justify-content: center;
        justify-items: center;
        //border:1px solid red;
        @include mobile {
          margin-bottom: 5px;
        }

        a {
          //border:1px solid red;
          width: 100%;
          display: block;
          text-transform: uppercase;
          text-align: center;
          font-size: 1.1rem;
          line-height: 30px;
          @include mobile {
            font-size: 1.5rem;
          }

          &:hover {
            text-decoration: none;
            background: rgba(211, 211, 211, 0.16);
          }
        }
      }
    }
  }
}